@use "sass:map"; // mapを使用する際に記述する

// //メディアクエリの設定
// /* hoverアクション
// _______________________________________________________________________________________________________*/ 


@mixin any-hover {
  @media (any-hover: hover) {
    &:hover {
      @content;
    }
  }
}







