@use 'sass:math';

//pxをvwに変換するfunction
@function _get_vw($size, $viewport: 375) {
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}

// font-sizeをpx から rem に変換する関数
// font-size: rem(16);
$baseFontSize: 16;
@function rem($pixels) {
  @return calc($pixels / $baseFontSize * 1rem);
}

//フォント用のmixin
@mixin font-vw($font_size: 10, $viewsize: 375) {
  font-size: $font_size * 1px;
  font-size: _get_vw($font_size, $viewsize);
}

//画像用のmixin
@mixin img-vw($img_size, $viewsize: 375) {
  width: $img_size * 1px;
  width: _get_vw($img_size, $viewsize);
}

//paddingやmargin用のmixin（widhtやheightでも使える）
@mixin p-vw($property, $size, $viewsize: 375) {
  #{$property}: $size * 1px;
  #{$property}: _get_vw($size, $viewsize);
}

// フォントをクランプを書き出す関数
@mixin clamp-fz($min, $max, $viewsize: 375) {
  font-size: rem($max);
  font-size: clamp(rem($min), calc(100vw / $viewsize * $max), rem($max));
}

// プロパティをクランプを書き出す関数

@mixin clamp-pt($property, $min, $max, $viewsize: 375) {
  #{$property}: $max * 1px;
  #{$property}: clamp($min * 1px, calc(100vw / $viewsize * $max), $max * 1px);
}

// Line-heightの計算
@mixin lh($num: 16, $line: 24) {
  line-height: calc($line / $num);
}

@function clamped($min-px, $max-px, $min-bp, $max-bp) {
  $slope: math.div($max-px - $min-px, $max-bp - $min-bp);
  $slope-vw: $slope * 100;
  $intercept-rems: rem($min-px - $slope * $min-bp);
  $min-rems: rem($min-px);
  $max-rems: rem($max-px);
  @return clamp(#{$min-rems}, #{$slope-vw}vw + #{$intercept-rems}, #{$max-rems});
}
