@use '../../global/' as g;

.l-under-top {
  position: relative;
  z-index: 0;
  overflow: hidden;
  &__main {
    padding: 60px 0 60px;
    background-color: g.$white;

    @include g.mq(md) {
      padding: 145px 0 150px;
    }
  }

  &__bk-content01 {
    position: absolute;
    // right: -120%;
    // bottom: -50%;
    // right: -92px;
    // bottom: -150px;
    right: -30vw;
    bottom: -8vw;

    @include g.mq(sm) {
      // right: -77%;
      // bottom: -40%;
      // right: -92px;
      // bottom: -150px;
      right: -20vw;
      bottom: -8vw;
    }

    @include g.mq(md) {
      // right: -92px;
      // bottom: -150px;
      right: -6vw;
      bottom: -9vw;
    }

    svg {
      // width: calc(100vw / 1160 * 795);
      width: clamp(400px, calc(100vw / 1160 * 795), 798px);
      aspect-ratio: 795.216 / 450.264;
    }
  }

  &__bk-content02 {
    position: absolute;
    // right: -88%;
    // bottom: -50%;
    right: -23vw;
    bottom: 3vw;

    @include g.mq(sm) {
      // right: -60%;
      // bottom: -40%;
      right: -15vw;
      bottom: 0vw;
    }

    @include g.mq(md) {
      // right: -100px;
      // bottom: -16px;
      right: -6vw;
      bottom: -1vw;
    }

    svg {
      width: clamp(400px, calc(100vw / 1160 * 798), 798px);
      aspect-ratio: 867.587 / 300.969;
    }
  }

  .ball {
    visibility: hidden;
    opacity: 0;
    animation: fadeIn 5s forwards;
  }

  @keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    80% {
      visibility: visible;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  &01 {
    .l-breadcrumbs,
    .l-breadcrumbs__lists {
      justify-content: flex-start;
    }
    .l-breadcrumbs__lists {
      @include g.mq('md') {
        padding-bottom: 10px;
      }
    }
  }
  &01__main {
    position: relative;
    z-index: 0;
    padding: 80px 0;
    overflow: hidden;
    background: #f8faf9;
    border-top: solid 1px #2995e5;
    @include g.mq('md') {
      padding: 121px 0 129px;
    }
  }
  &01__main01 {
    padding: 40px 0 65px;
    @include g.mq('md') {
      padding: 35px 0 57px;
    }
    &.style01 {
      @include g.mq('md') {
        padding: 35px 0 64px;
      }
    }
    .p-under-top01__img {
      margin-bottom: 30px;
      @include g.mq('md') {
        margin-bottom: 0;
      }
    }
    .p-under-top01__desc {
      margin-bottom: 16px;
      @include g.mq('md') {
        margin-bottom: 32px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &01__bk {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 122px;
    @include g.mq('md') {
      width: 120px;
      height: 245px;
    }
    @include g.mq('xl') {
      width: 171px;
      height: 317.41px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
