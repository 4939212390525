@use "../global" as g;

*,
*::before,
*::after {
  box-sizing: border-box;
}

// html {
//   font-size: 62.5%;
// }

body {
  position: relative;
  padding: 0;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: g.$Hiragino;
  font-size: g.rem(g.$sp-base);
  font-weight: 300;
  line-height: calc(32 / 16);
  color: inherit;
  color: g.$black-main;
  letter-spacing: 0;
  font-display: swap;
  background-color: g.$white;

  figure {
    margin-bottom: 0;
  }

  &.is-nav-open,
  &.is-lock {
    overflow: hidden;
  }

  .is_loadding & {
    overflow: hidden;
  }

  @include g.mq(xs) {
    position: relative;
  }

  @include g.mq(sm) {
    position: relative;
  }

  @include g.mq(md) {
    position: relative;
  }

  @include g.mq(lg) {
    position: relative;
  }

  @include g.mq(xl) {
    position: relative;
  }

  @include g.mq(xxl) {
    position: relative;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a[href^="tel:"] {
  @include g.mq(md) {
    pointer-events: none;
  }
}

a:hover {
  opacity: 0.8;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.clearfix:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  content: ".";
}
