@use "../../global/" as g;
@use "sass:map";

.l-header {
  position: fixed;
  top: 0;
  z-index: map.get(g.$layer, header);
  width: 100%;
  transition: 0.4s g.$easeInOutSine;
  transform: translateY(-100px);

  .is-header-show & {
    transform: none;
  }

  .is-lock & {
    transform: translateY(-100px);
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding-right: 30px;
    padding-left: 30px;
    background-color: rgba($color: g.$white, $alpha: 0.97);

    @include g.mq(md) {
      padding-right: 40px;
      padding-left: 30px;
      height: 72px;
    }

    @include g.mq(lg) {
      padding-right: 65px;
    }
  }

  //ロゴ周り

  &__logo {
    width: 100%;
    max-width: 133.12px;

    @include g.mq(md) {
      max-width: 143.5px;
    }
    // width: 100%;
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  //headrメニュー
  &__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include g.mq(md) {
      align-items: stretch;
      width: 100%;
      height: 100%;
    }

    &::before {
      position: absolute;
      top: 100%;
      left: 0px;
      width: 100%;
      height: 100vh;
      visibility: hidden;
      content: "";
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 0;
      transition: 0.4s ease 0s;
    }

    &.is-drop-open {
      &::before {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__menu-list {
    display: none;

    @include g.mq(md) {
      display: flex;
      gap: clamp(20px, calc(100vw / 1600 * 30), 60px);
    }

    @include g.mq(xxl) {
      gap: clamp(20px, calc(100vw / 1600 * 60), 60px);
    }
  }

  &__menu-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @include g.mq(md) {
      font-size: g.rem(16);
      line-height: calc(19.2 / 16);
      // cursor: pointer;
    }

    &:nth-last-of-type(1) {
      .l-header__menu-drop-down {
        right: 0;
        left: auto;
      }
    }

    &:focus,
    &:focus-within {
      > .l-header__menu-drop-down {
        visibility: visible;
        opacity: 1;
        transform: none;
      }
    }

    @include g.any-hover {
      > .l-header__menu-drop-down {
        visibility: visible;
        opacity: 1;
        transform: none;
      }

      .l-header__menu-item-box {
        &::after {
          transform: scale(1, 1);
          transform-origin: left top;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__menu-item-box {
    position: relative;
    font-family: g.$Oswald;
    font-size: g.rem(18);
    font-weight: 400;
    line-height: calc(32 / 18);
    letter-spacing: 0.04em;

    &::after {
      position: absolute;
      right: 0;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 1px;
      content: "";
      background-color: g.$black-main;
      transition: 0.4s ease-in-out;
      transition: transform 0.4s ease-in-out;
      transform: scale(0, 1);
      transform-origin: right top;
    }
  }

  &__menu-drop-down {
    position: absolute;
    top: calc(100% - 20px);
    left: 0;
    z-index: 1;
    padding: 20px 30px 30px;
    visibility: hidden;
    background-color: g.$white;
    box-shadow: 4px 4px 4px 4px rgba($color: g.$gray, $alpha: 0.5);
    opacity: 0;
    transition: 0.4s ease-in-out;
    transform: translateY(-20px);
  }

  &__menu-drop-down-item {
    white-space: nowrap;

    + .l-header__menu-drop-down-item {
      margin-top: 20px;
    }
    @include g.any-hover {
      a {
        opacity: 1;
      }
      .l-header__menu-drop-down-item-box {
        color: g.$blue;
      }
    }
  }

  &__menu-drop-down-item-box {
    transition: 0.4s ease-in-out;
  }

  &__hambergur {
    display: block !important;

    @include g.mq(md) {
      display: none !important;
    }
  }

  &__link-list {
    position: absolute;
    top: 100%;
    // position: fixed;
    // top: 110px;
    right: 0;
    display: none;

    @include g.mq(md) {
      display: block;
    }
  }

  &__link-item {
    position: relative;
    color: g.$white;
    background-color: transparent;
    writing-mode: vertical-lr;
    transition: 0.4s ease-in-out;

    @include g.mq(md) {
      height: 100%;
      // max-height: 200px;
      height: 200px;
      font-size: g.rem(16);
    }

    a {
      display: block;
      height: 100%;
    }

    @include g.any-hover {
      color: g.$blue;

      &::after {
        opacity: 0;
      }

      a {
        opacity: 1;
      }

      svg {
        fill: g.$blue;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 14px;
      transition: none;

      @include g.mq(md) {
        padding: 0 4px;
      }

      @include g.mq(lg) {
        padding: 0 14px;
      }
    }

    span {
      position: relative;
      display: inline-block;
      margin-top: 5px;
      letter-spacing: 0.04em;
    }

    svg {
      fill: g.$white;
      transition: 0.4s ease-in-out;
    }
  }

  // sp-nav
  &__sp-nav {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 0;
    overflow-y: auto;
    visibility: hidden;
    background-color: g.$white;
    transition: 0.4s ease-in-out;

    @include g.mq(md) {
      top: 72px;
    }

    .is-nav-open & {
      height: calc(100% - 60px);
      visibility: visible;
      opacity: 1;

      @include g.mq(lg) {
        height: calc(100% - 72px);
      }
    }
  }

  &__sp-nav-content {
    height: auto;
  }

  &__sp-nav-menu {
    padding: 20px 20px;
  }

  &__sp-nav-lists {
    border-bottom: 1px solid g.$border;
  }

  &__sp-nav-item {
    font-size: g.rem(16);
    font-weight: 700;
    letter-spacing: 0.02;

    .l-header__sp-nav-item-box {
      display: block;
      padding-bottom: 10px;
      padding-left: 10px;
    }
    .c-icon-blank {
      position: relative;
      &::after {
        position: absolute;
        top: calc(50% - 5px);
        right: 10px;
      }
    }

    + .l-header__sp-nav-item {
      border-top: 1px solid g.$border;
      .l-header__sp-nav-item-box {
        padding-top: 10px;
      }
    }

    .c-under-nav {
      position: relative;
      display: block;
      height: 100%;
      padding-right: 20px;

      &::before,
      &::after {
        position: absolute;
        top: calc(50% - 0.5px);
        right: 10px;
        display: inline-block;
        width: 13px;
        height: 1px;
        content: "";
        background-color: g.$blue;
      }

      &::after {
        transition: 0.4s ease-in-out;
        transform: rotate(90deg);
      }
    }

    &.is-drop-open {
      .l-header__sp-nav-item-box {
        // background-color: g.$gray02;
      }
      .c-under-nav {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }

  &__sp-nav-dropdown {
    display: none;
  }

  &__sp-nav-dropdown-list {
    padding: 12px 20px 20px;
  }

  &__sp-nav-dropdown-item {
    font-size: g.rem(14);
    font-weight: 500;

    @include g.mq(md) {
      font-size: g.rem(16);
    }

    + .l-header__sp-nav-dropdown-item {
      margin-top: 16px;
    }
  }

  &__sp-contact {
    padding-bottom: 40px;
    margin-top: 20px;
  }

  // sp-bottom-link

  &__sp-bottom-link {
    position: fixed;
    bottom: 0;
    width: 100%;

    @include g.mq(md) {
      display: none;
    }
  }
  &__sp-bottom-link-list {
    display: flex;
  }

  &__sp-bottom-link-item {
    position: relative;
    width: 50%;
    color: g.$white;
    background-color: transparent;
    transition: 0.4s ease-in-out;

    a {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      transition: none;
    }

    span {
      position: relative;
      display: inline-block;
      padding: 20px 0;
      font-size: g.rem(16);
      letter-spacing: 0.04em;
    }

    svg {
      display: inline-block;
      fill: g.$white;
      transition: 0.4s ease-in-out;
    }
  }
}

// サファリ
_::-webkit-full-page-media,
_:future,
:root .l-header__link-list {
  right: 40px;

  @include g.mq(md) {
    right: 60px;
  }
}
