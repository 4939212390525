@charset "UTF-8";
/*--------------------------------------------------------------
* フォントの読み込み
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* 使用font の変数
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* フォントサイズ の変数
----------------------------------------------------------------*/
/* pc
_______________________________________________________________________________________________________*/
/* sp
_______________________________________________________________________________________________________*/
/* =========================================================================================================
	共通定義する値
========================================================================================================= */
/* コンテンツ幅
_______________________________________________________________________________________________________*/
/* 使用色
_______________________________________________________________________________________________________*/
/* イージング
_______________________________________________________________________________________________________*/
/*
 * Easing function
 * Original：http://easings.net/
 */
/*--------------------------------------------------------------
* globalフォルダの_index.scss
----------------------------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  padding: 0;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: "Hiragino Kaku Gothic ProN", "Noto Sans JP", sans-serif;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 2;
  color: inherit;
  color: #1a1311;
  letter-spacing: 0;
  font-display: swap;
  background-color: #ffffff;
}
body figure {
  margin-bottom: 0;
}
body.is-nav-open, body.is-lock {
  overflow: hidden;
}
.is_loadding body {
  overflow: hidden;
}
@media screen and (min-width: 0) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 576px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1025px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1200px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1400px) {
  body {
    position: relative;
  }
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

@media screen and (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

a:hover {
  opacity: 0.8;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.clearfix:after {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  content: ".";
}

/*--------------------------------------------------------------
* foundationフォルダの_index.scss
----------------------------------------------------------------*/
.l-header {
  position: fixed;
  top: 0;
  z-index: 30;
  width: 100%;
  transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform: translateY(-100px);
}
.is-header-show .l-header {
  transform: none;
}
.is-lock .l-header {
  transform: translateY(-100px);
}
.l-header__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: rgba(255, 255, 255, 0.97);
}
@media screen and (min-width: 768px) {
  .l-header__inner {
    padding-right: 40px;
    padding-left: 30px;
    height: 72px;
  }
}
@media screen and (min-width: 1025px) {
  .l-header__inner {
    padding-right: 65px;
  }
}
.l-header__logo {
  width: 100%;
  max-width: 133.12px;
}
@media screen and (min-width: 768px) {
  .l-header__logo {
    max-width: 143.5px;
  }
}
.l-header__logo a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.l-header__menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .l-header__menu {
    align-items: stretch;
    width: 100%;
    height: 100%;
  }
}
.l-header__menu::before {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 100vh;
  visibility: hidden;
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.4s ease 0s;
}
.l-header__menu.is-drop-open::before {
  visibility: visible;
  opacity: 1;
}
.l-header__menu-list {
  display: none;
}
@media screen and (min-width: 768px) {
  .l-header__menu-list {
    display: flex;
    gap: clamp(20px, 1.875vw, 60px);
  }
}
@media screen and (min-width: 1400px) {
  .l-header__menu-list {
    gap: clamp(20px, 3.75vw, 60px);
  }
}
.l-header__menu-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .l-header__menu-item {
    font-size: 1rem;
    line-height: 1.2;
  }
}
.l-header__menu-item:nth-last-of-type(1) .l-header__menu-drop-down {
  right: 0;
  left: auto;
}
.l-header__menu-item:focus > .l-header__menu-drop-down, .l-header__menu-item:focus-within > .l-header__menu-drop-down {
  visibility: visible;
  opacity: 1;
  transform: none;
}
@media (any-hover: hover) {
  .l-header__menu-item:hover > .l-header__menu-drop-down {
    visibility: visible;
    opacity: 1;
    transform: none;
  }
  .l-header__menu-item:hover .l-header__menu-item-box::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }
}
.l-header__menu-item a {
  display: flex;
  align-items: center;
  height: 100%;
}
.l-header__menu-item-box {
  position: relative;
  font-family: "Oswald", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.7777777778;
  letter-spacing: 0.04em;
}
.l-header__menu-item-box::after {
  position: absolute;
  right: 0;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 1px;
  content: "";
  background-color: #1a1311;
  transition: 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transform: scale(0, 1);
  transform-origin: right top;
}
.l-header__menu-drop-down {
  position: absolute;
  top: calc(100% - 20px);
  left: 0;
  z-index: 1;
  padding: 20px 30px 30px;
  visibility: hidden;
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 4px rgba(213, 213, 213, 0.5);
  opacity: 0;
  transition: 0.4s ease-in-out;
  transform: translateY(-20px);
}
.l-header__menu-drop-down-item {
  white-space: nowrap;
}
.l-header__menu-drop-down-item + .l-header__menu-drop-down-item {
  margin-top: 20px;
}
@media (any-hover: hover) {
  .l-header__menu-drop-down-item:hover a {
    opacity: 1;
  }
  .l-header__menu-drop-down-item:hover .l-header__menu-drop-down-item-box {
    color: #003288;
  }
}
.l-header__menu-drop-down-item-box {
  transition: 0.4s ease-in-out;
}
.l-header__hambergur {
  display: block !important;
}
@media screen and (min-width: 768px) {
  .l-header__hambergur {
    display: none !important;
  }
}
.l-header__link-list {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
}
@media screen and (min-width: 768px) {
  .l-header__link-list {
    display: block;
  }
}
.l-header__link-item {
  position: relative;
  color: #ffffff;
  background-color: transparent;
  writing-mode: vertical-lr;
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .l-header__link-item {
    height: 100%;
    height: 200px;
    font-size: 1rem;
  }
}
.l-header__link-item a {
  display: block;
  height: 100%;
}
@media (any-hover: hover) {
  .l-header__link-item:hover {
    color: #003288;
  }
  .l-header__link-item:hover::after {
    opacity: 0;
  }
  .l-header__link-item:hover a {
    opacity: 1;
  }
  .l-header__link-item:hover svg {
    fill: #003288;
  }
}
.l-header__link-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
  transition: none;
}
@media screen and (min-width: 768px) {
  .l-header__link-item a {
    padding: 0 4px;
  }
}
@media screen and (min-width: 1025px) {
  .l-header__link-item a {
    padding: 0 14px;
  }
}
.l-header__link-item span {
  position: relative;
  display: inline-block;
  margin-top: 5px;
  letter-spacing: 0.04em;
}
.l-header__link-item svg {
  fill: #ffffff;
  transition: 0.4s ease-in-out;
}
.l-header__sp-nav {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 0;
  overflow-y: auto;
  visibility: hidden;
  background-color: #ffffff;
  transition: 0.4s ease-in-out;
}
@media screen and (min-width: 768px) {
  .l-header__sp-nav {
    top: 72px;
  }
}
.is-nav-open .l-header__sp-nav {
  height: calc(100% - 60px);
  visibility: visible;
  opacity: 1;
}
@media screen and (min-width: 1025px) {
  .is-nav-open .l-header__sp-nav {
    height: calc(100% - 72px);
  }
}
.l-header__sp-nav-content {
  height: auto;
}
.l-header__sp-nav-menu {
  padding: 20px 20px;
}
.l-header__sp-nav-lists {
  border-bottom: 1px solid #707070;
}
.l-header__sp-nav-item {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.02;
}
.l-header__sp-nav-item .l-header__sp-nav-item-box {
  display: block;
  padding-bottom: 10px;
  padding-left: 10px;
}
.l-header__sp-nav-item .c-icon-blank {
  position: relative;
}
.l-header__sp-nav-item .c-icon-blank::after {
  position: absolute;
  top: calc(50% - 5px);
  right: 10px;
}
.l-header__sp-nav-item + .l-header__sp-nav-item {
  border-top: 1px solid #707070;
}
.l-header__sp-nav-item + .l-header__sp-nav-item .l-header__sp-nav-item-box {
  padding-top: 10px;
}
.l-header__sp-nav-item .c-under-nav {
  position: relative;
  display: block;
  height: 100%;
  padding-right: 20px;
}
.l-header__sp-nav-item .c-under-nav::before, .l-header__sp-nav-item .c-under-nav::after {
  position: absolute;
  top: calc(50% - 0.5px);
  right: 10px;
  display: inline-block;
  width: 13px;
  height: 1px;
  content: "";
  background-color: #003288;
}
.l-header__sp-nav-item .c-under-nav::after {
  transition: 0.4s ease-in-out;
  transform: rotate(90deg);
}
.l-header__sp-nav-item.is-drop-open .c-under-nav::after {
  transform: rotate(0deg);
}
.l-header__sp-nav-dropdown {
  display: none;
}
.l-header__sp-nav-dropdown-list {
  padding: 12px 20px 20px;
}
.l-header__sp-nav-dropdown-item {
  font-size: 0.875rem;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .l-header__sp-nav-dropdown-item {
    font-size: 1rem;
  }
}
.l-header__sp-nav-dropdown-item + .l-header__sp-nav-dropdown-item {
  margin-top: 16px;
}
.l-header__sp-contact {
  padding-bottom: 40px;
  margin-top: 20px;
}
.l-header__sp-bottom-link {
  position: fixed;
  bottom: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .l-header__sp-bottom-link {
    display: none;
  }
}
.l-header__sp-bottom-link-list {
  display: flex;
}
.l-header__sp-bottom-link-item {
  position: relative;
  width: 50%;
  color: #ffffff;
  background-color: transparent;
  transition: 0.4s ease-in-out;
}
.l-header__sp-bottom-link-item a {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  transition: none;
}
.l-header__sp-bottom-link-item span {
  position: relative;
  display: inline-block;
  padding: 20px 0;
  font-size: 1rem;
  letter-spacing: 0.04em;
}
.l-header__sp-bottom-link-item svg {
  display: inline-block;
  fill: #ffffff;
  transition: 0.4s ease-in-out;
}

_::-webkit-full-page-media,
_:future,
:root .l-header__link-list {
  right: 40px;
}
@media screen and (min-width: 768px) {
  _::-webkit-full-page-media,
_:future,
:root .l-header__link-list {
    right: 60px;
  }
}

.l-footer {
  padding-top: 24px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  letter-spacing: 0.004em;
  line-height: 1;
  color: rgba(26, 19, 17, 0.06);
  font-size: 6.25rem;
  font-size: clamp(1.9375rem, 7.1428571429vw, 6.25rem);
}
@media screen and (min-width: 768px) {
  .l-footer {
    padding-top: 136px;
    text-align: right;
    position: relative;
    top: 5px;
  }
}

.l-breadcrumbs {
  position: relative;
  background-color: #d5d5d5;
}
.l-breadcrumbs__lists {
  display: flex;
  padding-top: 17px;
  padding-bottom: 20px;
  overflow-x: auto;
}
@media screen and (min-width: 768px) {
  .l-breadcrumbs__lists {
    justify-content: flex-end;
  }
}
.l-breadcrumbs__item {
  font-size: 1rem;
  color: #8e8e8e;
  letter-spacing: 0.02em;
  white-space: nowrap;
}
.l-breadcrumbs__item.is-show {
  color: #1a1311;
}
.l-breadcrumbs__item + .l-breadcrumbs__item {
  position: relative;
  padding-left: 15px;
  margin-left: 10px;
}
.l-breadcrumbs__item + .l-breadcrumbs__item::before {
  position: absolute;
  top: calc(50% - 5px);
  left: 0;
  display: inline-block;
  width: 5px;
  height: 10px;
  content: "";
  background-size: cover;
}

.l-under-top {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.l-under-top__main {
  padding: 60px 0 60px;
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .l-under-top__main {
    padding: 145px 0 150px;
  }
}
.l-under-top__bk-content01 {
  position: absolute;
  right: -30vw;
  bottom: -8vw;
}
@media screen and (min-width: 576px) {
  .l-under-top__bk-content01 {
    right: -20vw;
    bottom: -8vw;
  }
}
@media screen and (min-width: 768px) {
  .l-under-top__bk-content01 {
    right: -6vw;
    bottom: -9vw;
  }
}
.l-under-top__bk-content01 svg {
  width: clamp(400px, 68.5344827586vw, 798px);
  aspect-ratio: 795.216/450.264;
}
.l-under-top__bk-content02 {
  position: absolute;
  right: -23vw;
  bottom: 3vw;
}
@media screen and (min-width: 576px) {
  .l-under-top__bk-content02 {
    right: -15vw;
    bottom: 0vw;
  }
}
@media screen and (min-width: 768px) {
  .l-under-top__bk-content02 {
    right: -6vw;
    bottom: -1vw;
  }
}
.l-under-top__bk-content02 svg {
  width: clamp(400px, 68.7931034483vw, 798px);
  aspect-ratio: 867.587/300.969;
}
.l-under-top .ball {
  visibility: hidden;
  opacity: 0;
  animation: fadeIn 5s forwards;
}
@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  80% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.l-under-top01 .l-breadcrumbs,
.l-under-top01 .l-breadcrumbs__lists {
  justify-content: flex-start;
}
@media screen and (min-width: 768px) {
  .l-under-top01 .l-breadcrumbs__lists {
    padding-bottom: 10px;
  }
}
.l-under-top01__main {
  position: relative;
  z-index: 0;
  padding: 80px 0;
  overflow: hidden;
  background: #f8faf9;
  border-top: solid 1px #2995e5;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main {
    padding: 121px 0 129px;
  }
}
.l-under-top01__main01 {
  padding: 40px 0 65px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01 {
    padding: 35px 0 57px;
  }
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01.style01 {
    padding: 35px 0 64px;
  }
}
.l-under-top01__main01 .p-under-top01__img {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01 .p-under-top01__img {
    margin-bottom: 0;
  }
}
.l-under-top01__main01 .p-under-top01__desc {
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__main01 .p-under-top01__desc {
    margin-bottom: 32px;
  }
}
.l-under-top01__main01 .p-under-top01__desc:last-child {
  margin-bottom: 0;
}
.l-under-top01__bk {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 122px;
}
@media screen and (min-width: 768px) {
  .l-under-top01__bk {
    width: 120px;
    height: 245px;
  }
}
@media screen and (min-width: 1200px) {
  .l-under-top01__bk {
    width: 171px;
    height: 317.41px;
  }
}
.l-under-top01__bk svg {
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------
* layoutフォルダの_index.scss
----------------------------------------------------------------*/
/*--------------------------------------------------------------
* projectフォルダの_index.scss
----------------------------------------------------------------*/
/* =========================================================================================================
	* foundation   //基準の設定
========================================================================================================= */
/* =========================================================================================================
	* layout _l
========================================================================================================= */
/* =========================================================================================================
  * component _c
========================================================================================================= */