@use "../../global/" as g;

.l-footer {
  padding-top: 24px;
  font-family: g.$Oswald;
  font-weight: 400;
  letter-spacing: 0.004em;
  line-height: 1;
  color: rgba($color: g.$black-main, $alpha: 0.06);

  @include g.clamp-fz(31, 100, 1400);

  @include g.mq(md) {
    padding-top: 136px;
    text-align: right;
    position: relative;
    top: 5px;
  }
}
