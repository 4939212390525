@use '../../global/' as g;

.l-breadcrumbs {
  position: relative;
  background-color: g.$gray;

  &__lists {
    display: flex;
    padding-top: 17px;
    padding-bottom: 20px;
    overflow-x: auto;

    @include g.mq(md) {
      justify-content: flex-end;
    }
  }

  &__item {
    font-size: g.rem(16);
    color: #8e8e8e;
    letter-spacing: 0.02em;
    white-space: nowrap;

    &.is-show {
      color: g.$black-main;
    }

    + .l-breadcrumbs__item {
      position: relative;
      padding-left: 15px;
      margin-left: 10px;

      &::before {
        position: absolute;
        top: calc(50% - 5px);
        left: 0;
        display: inline-block;
        width: 5px;
        height: 10px;
        content: '';
        // background: url("../../images/common/icon_bread-arrow.svg") no-repeat
        //   center;
        background-size: cover;
      }
    }
  }
}
